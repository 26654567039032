import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage';


// Initialize Firebase
firebase.initializeApp({
    apiKey: "AIzaSyBxTgX6ZMmdwBtk2odDJHIufUio3punSfY",
    authDomain: "jj-univ-nrf.firebaseapp.com",
    projectId: "jj-univ-nrf",
    storageBucket: "jj-univ-nrf.appspot.com",
    messagingSenderId: "258548208088",
    appId: "1:258548208088:web:2ee59ef8603c3e561aa2ad",
    measurementId: "G-RL0RBQPTD0"
});


// 테스트 서버
// firebase.initializeApp({
//     apiKey: "AIzaSyANBy2SEPyuKvaY9e-mrSRX61AP-l_u1Qg",
//     authDomain: "jj-univ-nrf-dev.firebaseapp.com",
//     projectId: "jj-univ-nrf-dev",
//     storageBucket: "jj-univ-nrf-dev.appspot.com",
//     messagingSenderId: "481307334771",
//     appId: "1:481307334771:web:efc518a69f070df009f206",
//     measurementId: "G-G44855Z3HK"
// });

firebase.auth().languageCode = 'ko'

const auth = firebase.auth()
const firestore = firebase.firestore()
const functions = firebase.app().functions('asia-northeast3')

export {auth, firestore, functions, firebase}






