import Vue from 'vue'
import Router from 'vue-router'
import {firebase} from "../firebase/firebaseConfig";
import "firebase/auth";
import store from "../store";

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            // =============================================================================
            // COMMON LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/common/CommonView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/',
                    name: 'root',
                    component: () => import('../views/Home'),
                },
            ],
        },
        {
            // =============================================================================
            // ONLY BOTTOM LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/common/OnlyBottomView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/therapist/login',
                    name: 'therapistLogin',
                    component: () => import('../views/common/auth/therapist/TherapistLoginPage'),
                },
                {
                    path: '/therapist/findId',
                    name: 'therapistFindId',
                    component: () => import('../views/common/auth/therapist/TherapistFindId'),
                },
                {
                    path: '/therapist/findPassword',
                    name: 'therapistFindPassword',
                    component: () => import('../views/common/auth/therapist/TherapistFindPassword'),
                },
                {
                    path: '/user/login',
                    name: 'userLogin',
                    component: () => import('../views/common/auth/user/UserLoginPage'),
                },
                {
                    path: '/user/findId',
                    name: 'userFindId',
                    component: () => import('../views/common/auth/user/UserFindId'),
                },
                {
                    path: '/user/findPassword',
                    name: 'userFindPassword',
                    component: () => import('../views/common/auth/user/UserFindPassword'),
                },
            ],
        },
        {
            // =============================================================================
            // LOGIN NAV LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/common/LoginNavView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/terms/service',
                    name: 'termsService',
                    component: () => import('../views/common/terms/ServicePage'),
                },
                {
                    path: '/terms/private',
                    name: 'termsPrivate',
                    component: () => import('../views/common/terms/PrivatePage'),
                },
                {
                    path: '/therapist/signUp',
                    name: 'therapistSignUp',
                    component: () => import('../views/common/auth/therapist/TherapistSignUp'),
                },
                {
                    path: '/user/signUp',
                    name: 'userSignUp',
                    component: () => import('../views/common/auth/user/UserSignUp'),
                },
            ],
        },
        {
            // =============================================================================
            // USER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/user/UserView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/user',
                    name: 'user',
                    redirect: '/user/main',
                    meta: {
                        authRequired: true,
                        level: 2,
                    }
                },
                {
                    path: '/user/:menu',
                    name: 'userMain',
                    component: () => import('../views/user/UserHomePage'),
                    meta: {
                        authRequired: true,
                        level: 2,
                    }
                },
                {
                    path: '/user/:menu/:id',
                    name: 'userMainId',
                    component: () => import('../views/user/UserHomePage'),
                    meta: {
                        authRequired: true,
                        level: 2,
                    }
                },
                {
                    path: '/user/:menu/:id/:tool',
                    name: 'userEvaluation',
                    component: () => import('../views/user/UserHomePage'),
                    meta: {
                        authRequired: true,
                        level: 2,
                    }
                },
            ],
        },
        {
            // =============================================================================
            // THERAPIST PAGE LAYOUTS
            // =============================================================================
            path: '',
            component: () => import('@/layouts/therapist/TherapistView.vue'),
            children: [
                // =============================================================================
                // PAGES
                // =============================================================================
                {
                    path: '/therapist',
                    name: 'therapist',
                    redirect: '/therapist/patientsManage'
                },
                {
                    path: '/therapist/:menu',
                    name: 'therapistHome',
                    component: () => import('../views/therapist/TherapistHomePage'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/therapist/:menu/:id',
                    name: 'therapistDoEvaluation',
                    component: () => import('../views/therapist/TherapistHomePage'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },

            ]
        },
        {
            // =============================================================================
            // FULL LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/full_screen/FullScreenView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/pdf/:id',
                    name: 'pdf',
                    component: () => import('../views/Pdf.vue'),
                    meta: {
                        authRequired: true,
                        level: 1,
                    }
                },
                {
                    path: '/pdfUser/:id',
                    name: 'pdfUser',
                    component: () => import('../views/PdfUser.vue'),
                    meta: {
                        authRequired: true,
                        level: 2,
                    }
                },
                {
                    path: '/pages/error-404',
                    name: 'pages-error-404',
                    component: () => import('../views/Error404.vue'),
                    meta: {
                        title: '404',
                    }
                },
                {
                    path: '/master/login',
                    name: 'admin',
                    component: () => import('../views/MasterLoginPage.vue'),
                },
            ],
        },
        {
            // =============================================================================
            // MASTER LAYOUT ROUTES
            // =============================================================================
            path: '',
            component: () => import('@/layouts/master/MasterView.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: '/master',
                    name: 'master',
                    redirect: '/master/main'
                },
                {
                    path: '/master/main',
                    name: 'masterMain',
                    component: () => import('../views/master/Overview'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/therapistList',
                    name: 'masterTherapistList',
                    component: () => import('../views/master/therapist/TherapistList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/patientsList',
                    name: 'masterPatientsList',
                    component: () => import('../views/master/patients/PatientsList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/nonePatientsList',
                    name: 'masterNonePatientsList',
                    component: () => import('../views/master/patients/NonePatientsList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/evaluationList',
                    name: 'masterEvaluationList',
                    component: () => import('../views/master/EvaluationList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/videoUpload',
                    name: 'masterVideoUpload',
                    component: () => import('../views/master/class/VideoUpload'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/videoStandard',
                    name: 'masterVideoStandard',
                    component: () => import('../views/master/class/VideoStandard'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/videoAddPage',
                    name: 'masterVideoAddPage',
                    component: () => import('../views/master/class/VideoAddPage'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/eduCheckList',
                    name: 'masterEduCheckList',
                    component: () => import('../views/master/class/EduCheckList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/standardizationList',
                    name: 'masterStandardizationList',
                    component: () => import('../views/master/class/standardization/StandardizationList'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/masterInfo',
                    name: 'masterMasterInfo',
                    component: () => import('../views/master/MasterInfo'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/notice',
                    name: 'masterNotice',
                    component: () => import('../views/master/board/notice/BoardNotice'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/notice/add',
                    name: 'masterNoticeAdd',
                    component: () => import('../views/master/board/notice/BoardNoticeAdd'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/faq',
                    name: 'masterFaq',
                    component: () => import('../views/master/board/faq/BoardFaq'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/faq/add',
                    name: 'masterFaqAdd',
                    component: () => import('../views/master/board/faq/BoardFaqAdd'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/fna',
                    name: 'masterFna',
                    component: () => import('../views/master/board/fna/BoardFna'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/fna/add',
                    name: 'masterFnaAdd',
                    component: () => import('../views/master/board/fna/BoardFnaAdd'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/dataDownload',
                    name: 'masterDataDownload',
                    component: () => import('../views/master/board/dataDownload/DataDownload'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/dataDownload/add',
                    name: 'masterDataDownloadAdd',
                    component: () => import('../views/master/board/dataDownload/DataDownloadAdd'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/private',
                    name: 'masterPrivate',
                    component: () => import('../views/master/board/terms/TermsPrivate'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/service',
                    name: 'masterService',
                    component: () => import('../views/master/board/terms/TermsService'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
                {
                    path: '/master/terms/add',
                    name: 'masterTermsAdd',
                    component: () => import('../views/master/board/terms/TermsAdd'),
                    meta: {
                        authRequired: true,
                        level: 0,
                    }
                },
            ],
        },
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ]
})

const makeTitle = (title) =>
    title ? `${title} | KoROM Database` : "KoROM Database";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = makeTitle(to.meta.title);
    });
});

// eslint-disable-next-line no-unused-vars

router.beforeEach(async (to, from, next) => {
    const waitForFirebase = () => new Promise((resolve) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            unsubscribe();

            // let _level = 0;

            await store.dispatch('getUser', user)
                .then(() => {
                    // if (user) {
                    //     console.log(user)
                    // }
                })

            const firebaseCurrentUser = firebase.auth().currentUser
            if (to.meta.authRequired) {

                if (!(firebaseCurrentUser)) {
                    if (to.meta.level === 0) {
                        await router.push({path: '/master/login'}).catch(() => {
                        })
                    } else if (to.meta.level === 1) {
                        await router.push({path: '/therapist/login'}).catch(() => {
                        })
                    } else if (to.meta.level === 2) {
                        await router.push({path: '/user/login'}).catch(() => {
                        })
                    } else {

                        if (firebaseCurrentUser.email.includes('@koromadmin.com')) {
                            if (to.path !== '/master/main')
                                await router.push({path: '/master/main'}).catch(() => {
                                })
                        }
                    }
                } else {
                    if (firebaseCurrentUser.email.includes('@koromadmin.com')) {
                        if (to.meta.level === 1 || to.meta.level === 2) {
                            if (to.path !== '/master/main')
                                await router.push({path: '/master/main'}).catch(() => {
                                })
                        }
                    } else if (firebaseCurrentUser.email.includes('@koromusermail.world')) {
                        if (to.meta.level === 0 || to.meta.level === 1) {
                            if (to.path !== '/user/main')
                                await router.push({path: '/user/main'}).catch(() => {
                                })
                        }
                    } else {
                        if (to.meta.level === 0 || to.meta.level === 2) {
                            if (to.path !== '/therapist/patientsManage')
                                await router.push({path: '/therapist/patientsManage'}).catch(() => {
                                })
                        }
                    }
                }
            } else {
                if (user) {
                    if (firebaseCurrentUser.email.includes('@koromadmin.com')) {
                        if (to.path !== '/master/main')
                            await router.push({path: '/master/main'}).catch(() => {
                            })
                    } else if (firebaseCurrentUser.email.includes('@koromusermail.world')) {
                        if (to.path !== '/user/main')
                            await router.push({path: '/user/main'}).catch(() => {
                            })
                    } else {
                        if (to.path !== '/therapist/patientsManage')
                            await router.push({path: '/therapist/patientsManage'}).catch(() => {
                            })
                    }
                }
            }
            if (store.state.firebaseLoaded) {
                resolve();
            }
        });
    })
    await waitForFirebase();
    next()

});


export default router
